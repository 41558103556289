import React from 'react';
import type { ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';

interface ChooseCardInviteePrice {
  price: number;
}

export function ChooseCardInviteePrice({ price }: ChooseCardInviteePrice): ReactNode {
  const { t, formatNumber } = useIntl();

  const formatedPrice: string = price
    ? formatNumber(price, {
        currency: 'EUR',
        style: 'currency',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
      })
    : '';

  return (
    <p data-test-price>
      {price ? (
        <>
          {formatedPrice}
          <span className="body-1">{` ${t('cards.steps.choose-card.pricing-period')}`}</span>
        </>
      ) : (
        t('cards.steps.choose-card.included')
      )}
    </p>
  );
}
