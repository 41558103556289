import { useEffect, useState, type ReactElement } from 'react';
import cx from 'clsx';
import { Checkbox } from '@repo/design-system-kit';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { bulkVerificationStatusManager } from 'qonto/react/hooks/use-bulk-verification-status';
import styles from './styles.strict-module.css';

export function VerificationStatusSection(): ReactElement {
  const { verificationStatus: initialStatus } =
    bulkVerificationStatusManager.useBulkVerificationStatus();
  const {
    verificationStatus: { setVerificationStatus: bulkVerificationUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();
  const [verificationStatus, setVerificationStatus] = useState(initialStatus);
  const isIndeterminate = verificationStatus === null;

  useEffect(() => {
    setVerificationStatus(initialStatus);
  }, [initialStatus]);

  const handleChange = (): void => {
    setVerificationStatus(verificationStatus === null ? false : !verificationStatus);
    bulkVerificationUpdaterFn(verificationStatus === null ? false : !verificationStatus);
  };

  return (
    <section className={cx(styles.section)} data-testid="verification-status-section">
      <h2 className={cx(styles.title)} data-testid="title">
        Bookkeeping verification
      </h2>
      <Checkbox
        isSelected={!isIndeterminate && verificationStatus}
        onChange={handleChange}
        isIndeterminate={isIndeterminate}
        data-testid="checkbox"
      >
        Mark as verified
      </Checkbox>
    </section>
  );
}
