import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { ProductBadge } from '@repo/domain-kit';
import { clsx } from 'clsx';
import type { ReactElement } from 'react';
import React from 'react';
import { Addons } from 'qonto/constants/addons';
import { ManageAddon } from 'qonto/react/assets/icons/manage-addon';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface BillingRecurrenceTranslationsParams {
  recurrence: string;
  monthlyPrice: string;
  annualPrice: string;
}

interface MiniAddonCardProps {
  name: string;
  badgeTitle: string;
  billingRecurrence: string;
  nextBillingDate: string;
  trackingId: string;
  onClick: (trackingId: string) => void;
}

interface AddonSubscription {
  product: {
    localName: string;
    code: Addons.AccountsPayable | Addons.AccountsReceivable | Addons.ExpenseSpendManagement;
    monthlyPrice: {
      perMonthAmount: {
        value: number;
      };
    };
    annualPrice: {
      perYearAmount: {
        value: number;
      };
    };
  };
  recurrence: string;
  nextSubscriptionBillingDate: string;
}

interface HeaderAddonsOverviewProps {
  addonSubscriptions: AddonSubscription[];
  onClick: (trackingId: string) => void;
}

function MiniAddonCard({
  name,
  trackingId,
  badgeTitle,
  billingRecurrence,
  nextBillingDate,
  onClick,
}: MiniAddonCardProps): React.JSX.Element {
  function handleClick(): void {
    onClick(trackingId);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
      data-test-addon-card={name}
    >
      <div className="mb-8">
        <div className="mb-8">
          <ProductBadge traits={[{ name: 'active', title: badgeTitle }]} />
        </div>

        <h3 className={clsx(styles.name, 'body-1')} data-test-addon-name>
          {name}
        </h3>
      </div>

      <div>
        <div className="mb-12">
          <div data-test-billing-recurrence className={clsx(styles['color-secondary'], 'caption')}>
            {billingRecurrence}
          </div>
          <div data-test-next-billing-date className={clsx(styles['color-secondary'], 'caption')}>
            {nextBillingDate}
          </div>
        </div>
      </div>
    </div>
  );
}

function EmptyAddonCard({ onClick }: { onClick: (trackingId: string) => void }): React.JSX.Element {
  const { t } = useIntl();

  function handleClick(): void {
    onClick('main_card');
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  return (
    <div
      className={clsx(styles.container, styles.empty)}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      data-test-empty-card
    >
      <div className={styles.wrapper}>
        <ManageAddon />
        <h3 className="body-1" data-test-empty-card-description>
          {t('subscription.consumption-table.addon-overview.empty-state.title')}
        </h3>
      </div>
    </div>
  );
}

export function HeaderAddonsOverview({
  addonSubscriptions,
  onClick,
}: HeaderAddonsOverviewProps): ReactElement {
  const { t, formatNumber, locale } = useIntl();

  function billingRecurrenceTranslations({
    recurrence,
    monthlyPrice,
    annualPrice,
  }: BillingRecurrenceTranslationsParams): string {
    return recurrence === 'monthly'
      ? t('subscription.consumption-table.addon-overview.addon-list.monthly-billing', {
          amount: monthlyPrice,
        })
      : t('subscription.consumption-table.addon-overview.addon-list.annual-billing', {
          amount: annualPrice,
        });
  }

  function formatPrice(price: number): string {
    return formatNumber(price, {
      currency: 'EUR',
      style: 'currency',
    });
  }

  function formatDate(date: string): string {
    return dateToken({
      date,
      locale: locale as string,
      token: 'date-year-l',
    });
  }

  const trackingAddonsId = {
    [Addons.AccountsPayable]: 'ap_card',
    [Addons.AccountsReceivable]: 'ar_card',
    [Addons.ExpenseSpendManagement]: 'e&sm_card',
  };

  const addonsData: MiniAddonCardProps[] = addonSubscriptions.map(
    (addonSubscription: AddonSubscription) => ({
      name: addonSubscription.product.localName,
      trackingId: trackingAddonsId[addonSubscription.product.code],
      billingRecurrence: billingRecurrenceTranslations({
        recurrence: addonSubscription.recurrence,
        monthlyPrice: formatPrice(addonSubscription.product.monthlyPrice.perMonthAmount.value),
        annualPrice: formatPrice(addonSubscription.product.annualPrice.perYearAmount.value),
      }),
      nextBillingDate: t('subscription.consumption-table.addon-overview.addon-list.billing-date', {
        date: formatDate(addonSubscription.nextSubscriptionBillingDate),
      }),
      badgeTitle: t('subscription.consumption-table.addon-overview.badge'),
    })
  ) as MiniAddonCardProps[];

  return (
    <div className={styles.scroll}>
      {addonsData.map(addon => (
        <div key={addon.name}>
          <MiniAddonCard
            name={addon.name}
            trackingId={addon.trackingId}
            badgeTitle={addon.badgeTitle}
            billingRecurrence={addon.billingRecurrence}
            nextBillingDate={addon.nextBillingDate}
            onClick={onClick}
          />
        </div>
      ))}
      <EmptyAddonCard onClick={onClick} />
    </div>
  );
}
