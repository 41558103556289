import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { CrossOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface LabelProps extends PropsWithChildren {
  color?: string;
  onDismiss?: () => void;
}

export function Label({ children, color, onDismiss }: LabelProps): ReactNode {
  return (
    <div className={styles.container}>
      <div className={cx(styles.label, 'caption')} data-testid="label">
        {color ? (
          <div
            className={cx(styles['color-dot'])}
            style={{ backgroundColor: color }}
            data-testid="label-color-dot"
          />
        ) : null}
        <span>{children}</span>
      </div>
      {onDismiss ? (
        <Button
          variant="tertiary"
          iconOnly
          onPress={onDismiss}
          className={styles['dismiss-button']}
        >
          <CrossOutlined />
        </Button>
      ) : null}
    </div>
  );
}
