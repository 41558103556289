import { useEmberService, useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { Button, type BaseButtonProps } from '@repo/design-system-kit';
import { AddOnDetail } from '@repo/domain-kit';
import type { ReactElement } from 'react';

export interface AddonCardsProps {
  addons: Addon[];
  recurrence: SubscriptionRecurrences;
  onConfirm: (addon: Addon) => void;
  onToggleChange: (addonCode: string) => void;
  loadingAddonCode: string;
}
interface Trait {
  title: string;
  name: 'active' | 'free_trial';
}

interface Subscription {
  hasInitialTrial: boolean;
}

enum SubscriptionRecurrences {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

interface Price {
  value: string;
  currency: string;
}

interface Addon {
  name: string;
  code: string;
  type: string;
  brand_name: string;
  description: string;
  description_has_decoration: boolean;
  sub_description: string | null;
  monthly_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  annual_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  benefit_groups: [
    {
      title: string;
      badge: string | null;
      benefits: [
        {
          description: string;
          description_has_decoration: boolean;
          is_included: boolean;
        },
        {
          description: string;
          description_has_decoration: boolean;
          is_included: boolean;
        },
      ];
    },
  ];
}

export function AddonCards({
  addons,
  recurrence,
  onToggleChange,
  onConfirm,
  loadingAddonCode,
}: AddonCardsProps): ReactElement {
  const subscriptionManager = useEmberService('subscription-manager');
  const { formatNumber, t } = useIntl();

  const addonsInfo = addons.map(addon => {
    const {
      brand_name: brandName,
      code,
      sub_description: subDescription,
      benefit_groups: benefitGroups,
      monthly_price: monthlyPrice,
      annual_price: annualPrice,
    } = addon;
    const benefitsInfo = benefitGroups[0];
    const priceObjectByRecurrence =
      recurrence === SubscriptionRecurrences.MONTHLY ? monthlyPrice : annualPrice;

    const priceMonthly = formatNumber(Number(priceObjectByRecurrence.per_month_amount.value), {
      currency: priceObjectByRecurrence.per_month_amount.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
    });

    const priceObject =
      priceObjectByRecurrence[
        recurrence === SubscriptionRecurrences.MONTHLY ? 'per_month_amount' : 'per_year_amount'
      ];

    const price = formatNumber(Number(priceObject.value), {
      currency: priceObject.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
    });

    const ctaProps: { title: string; variant: BaseButtonProps['variant'] } = {
      title: t('subscription.change.addons.summary.choose-addon'),
      variant: 'primary',
    };

    if (subscriptionManager.getProduct(code)) {
      if (subscriptionManager.hasProductAndRecurrence(code, recurrence)) {
        ctaProps.title = t('subscription.change.addons.summary.remove-addon');
        ctaProps.variant = 'secondary';
      } else {
        ctaProps.title =
          recurrence === SubscriptionRecurrences.MONTHLY
            ? t('subscription.change.addons.summary.monthly')
            : t('subscription.change.addons.summary.annual');
      }
    }

    const traits: Trait[] = [];
    if (subscriptionManager.hasProductAndRecurrence(code, recurrence)) {
      traits.push({
        title: t('subscription.change.addons.badges.added'),
        name: 'active',
      });
    }

    if (
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- changing to nullish coalescing breaks the logic
      (subscriptionManager.currentSubscription as Subscription | undefined)?.hasInitialTrial ||
      subscriptionManager.hasAvailableTrialProduct(code, recurrence)
    ) {
      traits.push({
        title: t('subscription.change.addons.badges.free-trial'),
        name: 'free_trial',
      });
    }

    const prefix = priceObjectByRecurrence.prefix;
    const subtitle = subDescription ?? undefined;
    return {
      name: brandName,
      code,
      recurrence,
      price,
      priceMonthly,
      pricePeriod: priceObjectByRecurrence.period,
      benefitForAnnualPrice: annualPrice.annual_benefit,
      benefitForMonthlyPrice: monthlyPrice.annual_benefit,
      features: benefitsInfo.benefits.map(benefit => benefit.description),
      featuresTitle: benefitsInfo.title,
      traits,
      subtitle: subtitle && prefix ? `${prefix} ${subtitle}` : subtitle,
      cta: (
        <Button
          variant={ctaProps.variant}
          onPress={() => {
            onConfirm(addon);
          }}
          isLoading={loadingAddonCode === code}
          data-test-cta
        >
          {ctaProps.title}
        </Button>
      ),
    };
  });

  return (
    <>
      {addonsInfo.map(addon => (
        <AddOnDetail
          name={addon.name}
          annualSwitch={
            recurrence === SubscriptionRecurrences.MONTHLY ? (
              <Button
                className="caption"
                onPress={() => {
                  onToggleChange(addon.code);
                }}
                variant="tertiary"
                data-test-addon-detail-card-billing-recurrence-button
              >
                {addon.benefitForMonthlyPrice}
              </Button>
            ) : null
          }
          key={addon.code}
          recurrence={recurrence}
          subtitle={addon.subtitle}
          price={addon.price}
          priceMonthly={addon.priceMonthly}
          billingRecurrence={addon.benefitForAnnualPrice}
          pricePeriod={addon.pricePeriod}
          featuresTitle={addon.featuresTitle}
          features={addon.features}
          traits={addon.traits}
          mainButton={addon.cta}
        />
      ))}
    </>
  );
}
