import { useContext, type PropsWithChildren, type ReactNode, type UIEventHandler } from 'react';
import {
  Dialog,
  OverlayTriggerStateContext,
  Button as ReactAriaButton,
  Popover as ReactAriaPopover,
  type ButtonProps,
  type PopoverProps,
  type PressEvent,
} from 'react-aria-components';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { ChevronTopOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface CustomPopoverProps extends Omit<PopoverProps, 'children'> {
  children: ReactNode;
  width?: number;
}

interface PopoverSectionProps extends PropsWithChildren {
  className?: string;
}

export interface OverlayTriggerState {
  readonly isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export function Popover({ children, width = 272, ...props }: CustomPopoverProps): ReactNode {
  const portalContainer = document.querySelector('#ra-popover-container') ?? undefined;

  return (
    <ReactAriaPopover
      UNSTABLE_portalContainer={portalContainer}
      offset={-60}
      placement="bottom start"
      {...props}
    >
      <Dialog
        className={cx(styles.container)}
        data-testid="popover"
        style={{ width: `${width.toString()}px` }}
      >
        {children}
      </Dialog>
    </ReactAriaPopover>
  );
}

interface PopoverHeaderProps extends PropsWithChildren {
  className?: string;
}

export function PopoverHeader({ children, className }: PopoverHeaderProps): ReactNode {
  const triggerState = useContext<OverlayTriggerState>(OverlayTriggerStateContext);

  return (
    <header className={cx(styles.header, className)} data-testid="popover-header">
      {children}
      <Button
        className={cx(styles['header-button'])}
        onPress={triggerState.close}
        variant="tertiary"
        data-testid="popover-close-button"
        iconOnly
      >
        <ChevronTopOutlined aria-hidden="true" className={cx(styles.chevron)} />
      </Button>
    </header>
  );
}

interface PopoverSectionProps extends PropsWithChildren {
  className?: string;
  onScroll?: UIEventHandler<HTMLElement>;
}

export function PopoverSection({ children, className, ...props }: PopoverSectionProps): ReactNode {
  return (
    <section className={cx(styles.section, className)} data-testid="popover-section" {...props}>
      {children}
    </section>
  );
}

interface PopoverButtonProps extends Omit<ButtonProps, 'children'> {
  onPress: (e: PressEvent) => void;
  slots: {
    icon: ReactNode;
    text: ReactNode;
  };
  'data-testid'?: string;
}

export function PopoverButton({ onPress, slots, ...props }: PopoverButtonProps): ReactNode {
  return (
    <ReactAriaButton
      className={cx(styles.button, 'overlay')}
      onPress={onPress}
      data-testid="popover-button"
      {...props}
    >
      {slots.icon}
      <span data-testid="button-text" className="body-2">
        {slots.text}
      </span>
    </ReactAriaButton>
  );
}
