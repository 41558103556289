import { useCallback, useState, type FormEvent, type ReactNode } from 'react';
import { Button, Form } from 'react-aria-components';
import cx from 'clsx';
import { useEventCallback } from 'usehooks-ts';
import { TextArea } from '@repo/design-system-kit';
import { ShareFilled } from 'qonto/react/assets/icons';
import type { Transaction } from 'qonto/react/graphql';
import { useCreateComment } from 'qonto/react/hooks/use-create-comment';
import styles from './styles.strict-module.css';

export function CommentCreateForm({ transaction }: { transaction: Transaction }): ReactNode {
  const [comment, setComment] = useState('');
  const { mutate, isPending: isSubmitting } = useCreateComment(transaction);
  const [isFocused, setIsFocused] = useState(false);

  const onCommentCreated = useCallback((): void => {
    setComment('');
  }, []);

  const handleSubmit = useEventCallback((e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!comment.trim()) return;
    setIsFocused(false);

    mutate(
      { commentContent: comment },
      {
        onSuccess: () => {
          onCommentCreated();
        },
      }
    );
  });

  const handleChange = useEventCallback((e: FormEvent<HTMLTextAreaElement>): void => {
    setComment(e.currentTarget.value);
  });

  return (
    <Form onSubmit={handleSubmit} className={cx(styles['create-form'])}>
      <TextArea
        aria-label="Add a comment"
        placeholder="Add a comment"
        data-testid="comment-create-input"
        disabled={isSubmitting}
        value={comment}
        onChange={handleChange}
        onFocus={() => {
          setIsFocused(true);
        }}
        className={cx(styles['comment-text-area'], isFocused && styles.focused, 'body-1')}
      />
      <Button
        type="submit"
        className={cx(styles['create-button'], 'btn btn--icon-only btn--primary')}
        data-testid="create-button"
        aria-label="Create a comment"
        isDisabled={isSubmitting || !comment.trim()}
      >
        <ShareFilled date-testid="submit-icon" />
      </Button>
    </Form>
  );
}
