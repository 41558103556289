import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { type ReactElement } from 'react';
import { INSTANT_DECLINED_REASONS } from 'qonto/constants/transfers';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { canDeclinedInstantTransferBeRetried } from 'qonto/utils/transfers';
import styles from './styles.strict-module.css';

export const ILLUSTRATION_PATHS = {
  NO_RETRY: '/illustrations/transfers/error-right.svg',
  RETRY: '/illustrations/transfers/instant-declined-retry.svg',
};

interface DeclinedProps {
  reason: string;
}

export function Declined({ reason }: DeclinedProps): ReactElement {
  const { t } = useIntl();

  const descriptions: Record<string, string> = {
    [INSTANT_DECLINED_REASONS.ACCOUNT_UNREACHABLE]: t(
      'transfers.modals.instant.error.unreachable-beneficiary.description'
    ),
    [INSTANT_DECLINED_REASONS.INST_BENEFICIARY_INVALID]: t(
      'transfers.modals.instant.error.instant-invalid-beneficiary.description'
    ),
    [INSTANT_DECLINED_REASONS.INST_BENEFICIARY_NOT_SEPA]: t(
      'transfers.modals.instant.error.invalid-beneficiary.description'
    ),
    [INSTANT_DECLINED_REASONS.CREDITOR_TIMEOUT]: t(
      'transfers.modals.instant.error.declined.description'
    ),
    [INSTANT_DECLINED_REASONS.FORMAT_REJECTED]: t(
      'transfers.modals.instant.error.declined.description'
    ),
    [INSTANT_DECLINED_REASONS.PAYMENT_FAILED]: t(
      'transfers.modals.instant.error.declined.description'
    ),
    [INSTANT_DECLINED_REASONS.INST_PROCESSING_ERROR]: t(
      'transfers.modals.instant.error.processing-error.description'
    ),
    [INSTANT_DECLINED_REASONS.DISPOSITION_ERROR]: t(
      'transfers.modals.instant.error.regulatory-error.description'
    ),
    [INSTANT_DECLINED_REASONS.OPERATIONAL_ERROR]: t(
      'transfers.modals.instant.error.regulatory-error.description'
    ),
    [INSTANT_DECLINED_REASONS.SCREENING_REJECTED]: t(
      'transfers.modals.instant.error.regulatory-error.description'
    ),
    [INSTANT_DECLINED_REASONS.INSUFFICIENT_FUNDS]: t(
      'transfers.modals.instant.error.not-enough-funds.description'
    ),
  };

  const canBeRetried = canDeclinedInstantTransferBeRetried(reason);

  return (
    <section className={styles.wrapper} data-testid="declined">
      <StaticThemedAsset
        assetPath={canBeRetried ? ILLUSTRATION_PATHS.RETRY : ILLUSTRATION_PATHS.NO_RETRY}
        className={cx(
          styles.illustration,
          canBeRetried ? styles['illustration--retry'] : styles['illustration--error']
        )}
        data-testid="illustration"
      />

      <h1 className="title-1 mb-16" data-testid="title">
        {canBeRetried
          ? t('transfers.modals.instant.error.fallback-title')
          : t('transfers.modals.instant.error.title')}
      </h1>

      <p className="body-1" data-testid="description">
        {descriptions[reason] ?? t('transfers.modals.instant.error.generic.description')}
      </p>
    </section>
  );
}
