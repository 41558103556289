/* eslint-disable camelcase -- Reward API uses snake_case for its properties */
import type { ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { REWARDS_STATUS } from 'qonto/constants/referral';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface Reward {
  status: {
    value: (typeof REWARDS_STATUS)[keyof typeof REWARDS_STATUS];
    date?: string;
  };
  earned_date: string;
  reward: string;
  referee_name: string;
  waiting_for_kyb: boolean;
  amount: {
    value: number;
    currency: string;
  };
}

interface ListItemProps extends React.ComponentPropsWithoutRef<'li'> {
  reward: Reward;
}

function useDate({ date }: { date?: string }): string {
  const { locale } = useIntl();
  if (!date) return '';
  return dateToken({
    date,
    locale: typeof locale === 'string' ? locale : locale[0],
    token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
  });
}

function useDateLabel({
  status,
  waiting_for_kyb,
}: Pick<Reward, 'status' | 'waiting_for_kyb'>): string {
  const { t } = useIntl();
  const date = useDate({ date: status.date });

  switch (status.value) {
    case REWARDS_STATUS.EARNED:
      return t('referral.tiered-program.sidebar.earn.label', { date });
    case REWARDS_STATUS.EXPIRED:
      return t('referral.tiered-program.sidebar.expiration.label', { date });
    case REWARDS_STATUS.PENDING:
      if (waiting_for_kyb) {
        return t('referral.tiered-program.sidebar.pending.pending-kyb-label');
      } else if (status.date) {
        return t('referral.tiered-program.sidebar.pending.expiration-label', { date });
      }
      return t('referral.tiered-program.sidebar.pending.no-expiration-label');
  }
}

export function ListItem({ reward }: ListItemProps): ReactNode {
  const { formatMoney } = useIntl();
  const dateLabel = useDateLabel({
    status: reward.status,
    waiting_for_kyb: reward.waiting_for_kyb,
  });

  return (
    <li className={cx(styles.wrapper)}>
      <div>
        <p
          role="heading"
          aria-level={4}
          className={cx(
            styles.title,
            reward.status.value === REWARDS_STATUS.EXPIRED && styles.expired
          )}
        >
          {reward.referee_name}
        </p>
        <p role="status" className={cx(styles.date)}>
          {dateLabel}
        </p>
      </div>
      {reward.status.value !== REWARDS_STATUS.EXPIRED && (
        <data className={cx(styles.amount)} value={reward.amount.value}>
          {formatMoney(reward.amount.value, { currency: reward.amount.currency, signus: true })}
        </data>
      )}
    </li>
  );
}
