import { type ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { RECURRENCE } from 'qonto/constants/invoice-subscriptions';

interface FrequencyProps {
  frequency?: {
    value?: number;
    recurrence: RECURRENCE;
  };
}

export function Frequency({ frequency }: FrequencyProps): ReactNode {
  const { t } = useIntl();

  if (!frequency) return null;

  const getFrequencyText = (): string => {
    const { value, recurrence } = frequency;

    if (value && value > 1) {
      return t('recurring-invoices.list.table.row.cell.custom-frequency', {
        number: value,
        frequency: recurrence,
      });
    }

    const translationKeys: Record<RECURRENCE, string> = {
      [RECURRENCE.WEEKLY]: 'recurring-invoices.frequency.option.weekly',
      [RECURRENCE.MONTHLY]: 'recurring-invoices.frequency.option.monthly',
      [RECURRENCE.QUARTERLY]: 'recurring-invoices.frequency.option.quarterly',
      [RECURRENCE.YEARLY]: 'recurring-invoices.frequency.option.yearly',
    };

    return t(translationKeys[recurrence]);
  };

  return getFrequencyText();
}
