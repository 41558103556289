import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useCounter, useInterval, useTimeout } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import cs from 'clsx';
import { INSIGHTS_ANIMATION_DURATION } from 'qonto/constants/supplier-invoice';
import ENV from 'qonto/config/environment';

const isTesting = (ENV as { environment: string }).environment === 'test';
const DELAY_FACTOR = isTesting ? 1 : 10;
const DEFAULT_TIMEOUT_DELAY = 0;

/**
 * Calculate the span by which add/subtract the amount on each interval
 * to reach the final value within the INSIGHTS_ANIMATION_DURATION
 */
function calculateIncrement(start: number, end: number, animationDuration: number): number {
  return (end - start) / (animationDuration / DELAY_FACTOR) || 1;
}

/**
 * Refine the increment during the intervals to prevent the counter from going
 * over the end value
 */
function adjustIncrement(increment: number, limit: number, counter: number): number {
  if (increment > limit - counter) {
    return limit - counter;
  }
  return increment;
}

interface UseAnimateCounterReturn {
  count: number;
  startAnimation: () => void;
}

interface UseAnimateCounterArgs {
  start: number;
  end: number;
  clearTimeout: () => void;
}

const useAnimateCounter = ({
  start,
  end,
  clearTimeout,
}: UseAnimateCounterArgs): UseAnimateCounterReturn => {
  const { count, setCount } = useCounter(start);

  const [intervalValue, setIntervalValue] = useState<typeof DELAY_FACTOR | null>(null);

  const startAnimation = (): void => {
    setIntervalValue(DELAY_FACTOR);
  };

  const clearInterval = (): void => {
    setIntervalValue(null);
    clearTimeout();
    setCount(end);
  };

  const increment = calculateIncrement(start, end, INSIGHTS_ANIMATION_DURATION);

  const animationFn = (): void => {
    if (start > end) {
      if (count < end) {
        clearInterval();
        return;
      }
      setCount(c => c + adjustIncrement(increment, start, c));
      return;
    }

    if (count >= end) {
      clearInterval();
      return;
    }
    setCount(c => c + adjustIncrement(increment, end, c));
  };

  useInterval(animationFn, intervalValue);

  return {
    count,
    startAnimation,
  };
};

interface CounterProps {
  isLoading: boolean;
  previousValue?: number;
  currentValue?: number;
  delay: number;
  className?: string;
}

function AnimatedCounter({
  previousValue = 0,
  currentValue,
  delay = DEFAULT_TIMEOUT_DELAY,
  className,
}: Omit<CounterProps, 'isLoading'>): JSX.Element {
  const intl = useEmberService('intl');
  const [timeoutDelay, setTimeoutDelay] = useState<number | null>(delay);

  const { count, startAnimation } = useAnimateCounter({
    start: previousValue,
    end: currentValue ?? previousValue,
    clearTimeout() {
      setTimeoutDelay(null);
    },
  });

  // This effect triggers the animation also when the currentValue
  // prop gets updated
  useEffect(() => {
    if (currentValue !== count) {
      startAnimation();
    }
  }, [count, startAnimation, currentValue]);

  useTimeout(startAnimation, timeoutDelay);

  return (
    <div className={cs(className)} data-testid="insight-figure">
      {intl.formatNumber(count, { currency: 'EUR', style: 'currency' })}
    </div>
  );
}

export function Counter(props: CounterProps): JSX.Element {
  const intl = useEmberService('intl');

  if (props.isLoading) {
    return (
      <div className={cs(props.className)} data-testid="insight-figure">
        {intl.formatNumber(props.previousValue ?? 0, { currency: 'EUR', style: 'currency' })}
      </div>
    );
  }

  return <AnimatedCounter {...props} />;
}
