import { useState, type CSSProperties, type ReactElement, type ReactNode } from 'react';
import cx from 'clsx';
import { flexRender, type Header } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './styles.strict-module.css';

interface ResizerProps<TData, TValue> {
  header: Header<TData, TValue>;
}

function Resizer<TData, TValue>({ header }: ResizerProps<TData, TValue>): ReactElement {
  return (
    <div
      {...{
        onMouseDown: header.getResizeHandler(),
        onTouchStart: header.getResizeHandler(),
        style: {
          transform: header.column.getIsResizing() ? `translateX(0px)` : '',
        },
      }}
      className={cx(styles.resizer, styles.ltr, {
        isResizing: header.column.getIsResizing(),
      })}
    >
      <span className={styles['border-resizer']} />
      <span className={styles['middle-resizer']} />
      <span className={styles['border-resizer']} />
    </div>
  );
}

interface HeaderCellProps<TData, TValue> {
  header: Header<TData, TValue>;
  align?: 'right';
}

export function HeaderCell<TData, TValue>({
  header,
  align,
}: HeaderCellProps<TData, TValue>): ReactNode {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });
  const [isHovered, setIsHovered] = useState(false);

  const isDraggable = !header.column.getIsPinned();
  const isPinned = header.column.getIsPinned();
  const canResize = header.column.getCanResize();
  const isFixedColumn = header.index === 0;

  const getPadding = (): string => {
    return '0 16px';
  };

  const dndStyle: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    zIndex: isPinned || isDragging || isHovered ? 2 : 1,
    position: 'sticky',
    width: `${header.column.getSize().toString()}px`,
    maxWidth: `${header.column.getSize().toString()}px`,
    left: isPinned === 'left' ? `${header.column.getStart('left').toString()}px` : undefined,
    top: 0,
    borderRight: isFixedColumn ? '1.25px solid var(--border-tertiary)' : 'none',
    minWidth: isFixedColumn ? '300px' : 'none',
    boxShadow: isDragging ? 'var(--shadow-high)' : 'none',
  };

  return (
    <th
      className={cx(styles['table-header'])}
      style={{ ...dndStyle }}
      colSpan={header.colSpan}
      ref={setNodeRef}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      data-testid={`col-header-${header.id}`}
    >
      <button
        type="button"
        className={cx(
          styles['table-header-content'],
          isFixedColumn && styles['fixed-column'],
          align === 'right' && styles.right,
          isDragging ? styles['on-drag'] : '',
          'overlay'
        )}
        style={{ padding: getPadding() }}
        {...(isDraggable ? { ...attributes, ...listeners } : {})}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </button>
      {canResize ? <Resizer header={header} /> : null}
    </th>
  );
}
