import { useEffect, useState, type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEmberService, useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { Tooltip, Button } from '@repo/design-system-kit';
import { LightningOutlined } from 'qonto/react/assets/icons';
import type { LabelCategorizationRule, LabelIdentifier } from 'qonto/react/models/label';
import type { TransactionModel } from 'qonto/react/models/transaction';
import styles from './styles.strict-module.css';

interface LabelCategorizationProps {
  labelIds: LabelIdentifier[];
  transactions: TransactionModel[];
  hideDisclaimer?: boolean;
  onGenerateRules: (rules: LabelCategorizationRule[]) => void;
  resetSelectedLabelIds: (callback: () => void) => void;
}

export function LabelCategorization({
  labelIds,
  transactions = [],
  hideDisclaimer,
  onGenerateRules,
  resetSelectedLabelIds,
}: LabelCategorizationProps): ReactNode {
  const { t } = useIntl();
  const segment = useEmberService('segment');
  const [initLabelIds, setInitLabelIds] = useState(labelIds);
  const [initTransactions, setInitTransactions] = useState(transactions);

  const listState = new Map<string, string | undefined>([]);

  const categorizationCriterias = transactions.map(({ counterpartyName, rawCounterpartyName }) => ({
    counterpartyName,
    rawCounterpartyName,
  }));
  const uniqueCounterparties = [
    ...new Set(
      categorizationCriterias.map(
        criteria => criteria.counterpartyName || criteria.rawCounterpartyName
      )
    ),
  ];
  const hasMultipleCounterparties = uniqueCounterparties.length > 1;
  const remainingCounterparties = uniqueCounterparties.slice(2);

  const getRemainingCounterpartiesString = (): string => {
    if (remainingCounterparties.length > 1) {
      return `${remainingCounterparties.slice(0, -1).join(', ')} ${t('transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.tooltip', { counterparty_name: remainingCounterparties.at(-1) ?? '' })}`;
    }
    return remainingCounterparties[0] ?? '';
  };

  labelIds.forEach(({ labelListId, labelId }) => {
    listState.set(labelListId, labelId);
  });

  Array.from(listState).forEach(([key]) => {
    const isUndefined = !labelIds.find(({ labelListId }) => labelListId === key);
    if (isUndefined) listState.set(key, undefined);
  });

  const trackGenerateRules = (includePast: boolean): void => {
    segment.track('analytic_labels_generate_rules_clicked', {
      origin: 'transactions-details',
      type: includePast ? 'past-future' : 'future',
      bulk_selection: transactions.length > 1,
      number_of_counterparties: uniqueCounterparties.length,
    });
  };

  const handleGenerateRules = (includePast: boolean): void => {
    const categorizationRules = Array.from(listState)
      .map(([labelListId, labelId]) => {
        return categorizationCriterias.map(matchingCriteria => ({
          labelListId,
          labelId,
          matchingCriteria,
          includePast,
        }));
      })
      .flat() as LabelCategorizationRule[];

    trackGenerateRules(includePast);
    onGenerateRules(categorizationRules);
    setInitLabelIds(labelIds);
    setInitTransactions(transactions);
    listState.clear();
  };

  const hasLabelChange = JSON.stringify(initLabelIds) !== JSON.stringify(labelIds);
  const hasTransactionChange = initTransactions.some(
    ({ id }) => !transactions.find(transaction => transaction.id === id)
  );

  const isDisclosable = !hasTransactionChange && hasLabelChange;

  const resetTransactionsAndLabels = (): void => {
    setInitTransactions(transactions);
    setInitLabelIds(labelIds);
  };

  useEffect(() => {
    resetSelectedLabelIds(resetTransactionsAndLabels);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding all deps creates an infinite loop due to state dependencies with Ember on labelIds
  }, [hasTransactionChange, resetSelectedLabelIds]);

  return (
    <AnimatePresence>
      {isDisclosable && !hideDisclaimer ? (
        <motion.div
          initial={{ opacity: 0, height: 0.8 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <article className={styles['categorization-card']} data-testid="label-categorization">
            <h4 className={styles['categorization-card-title']} data-testid="title">
              <LightningOutlined className="mr-8" />
              {t('transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.title', {
                count: labelIds.length,
              })}
            </h4>
            {hasMultipleCounterparties ? (
              <p className="mb-16 body-2" data-testid="bulk-counterpartynames">
                <span>
                  {t(
                    'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.bulk.message',
                    {
                      count: labelIds.length,
                      counterparty_name_one: uniqueCounterparties[0] ?? '',
                      counterparty_name_two: uniqueCounterparties[1] ?? '',
                    }
                  )}
                </span>
                {remainingCounterparties.length > 0 && (
                  <>
                    {' '}
                    <Tooltip
                      label={getRemainingCounterpartiesString()}
                      placement="top"
                      className={styles.tooltip}
                      data-testid="tooltip-content"
                    >
                      <Button variant="tertiary" data-testid="remaining-counterpartynames">
                        <span>
                          {t(
                            'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.bulk.counterparty-placeholder',
                            {
                              count: remainingCounterparties.length,
                            }
                          )}
                        </span>
                      </Button>
                    </Tooltip>
                  </>
                )}
                <span>.</span>
              </p>
            ) : (
              <p className="mb-16 body-2" data-testid="single-counterpartyname">
                {t(
                  'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.single',
                  { count: labelIds.length, counterparty_name: uniqueCounterparties[0] ?? '' }
                )}
              </p>
            )}
            <footer className={styles['categorization-actions']}>
              <button
                type="button"
                className="btn btn--secondary btn--small"
                onClick={() => {
                  handleGenerateRules(true);
                }}
                data-testid="cta-past-future"
              >
                {t(
                  'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.button.past-future'
                )}
              </button>
              <button
                type="button"
                className="btn btn--primary btn--small"
                onClick={() => {
                  handleGenerateRules(false);
                }}
                data-testid="cta-future"
              >
                {t(
                  'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.button.future'
                )}
              </button>
            </footer>
          </article>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
