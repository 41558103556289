import { Badge, type CountryCode, Flag } from '@repo/design-system-kit';
import { type ComponentProps, type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface Currency {
  countryCode: CountryCode;
  currencyCode: string;
  currencyDisplayName?: string;
}

interface CurrencyProps {
  currency: Currency;
  badgeOptions?: ComponentProps<typeof Badge>;
  isoCodeOnly?: boolean;
}

export function Currency({
  currency: { countryCode, currencyCode, currencyDisplayName },
  badgeOptions,
  isoCodeOnly = false,
  ...props
}: CurrencyProps): ReactNode {
  const formattedCurrencyName =
    isoCodeOnly || !currencyDisplayName || currencyCode === currencyDisplayName
      ? currencyCode
      : `${currencyCode} - ${currencyDisplayName}`;

  return (
    <div className={styles.currency} data-test-currency={currencyCode} {...props}>
      <Flag className={styles.flag} code={countryCode} data-test-currency-flag={countryCode} />

      <span data-test-currency-name>{formattedCurrencyName}</span>

      {badgeOptions ? (
        <Badge className={styles.badge} {...badgeOptions} data-test-currency-badge />
      ) : null}
    </div>
  );
}
