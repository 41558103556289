import type { ReactElement } from 'react';
import { Badge, BadgeStatus } from '@repo/design-system-kit';
import {
  Selector,
  type SelectorProps,
} from 'qonto/react/components/account-receivable/summary/selector/selector';
import { Company, Calculator, Invoice, Pole } from 'qonto/react/assets/icons/account-receivable';
import styles from './styles.strict-module.css';

const icons = {
  pole: Pole,
  company: Company,
  invoice: Invoice,
  calculator: Calculator,
};

export type SubStepStatus = 'toDo' | 'prefilled' | 'confirmed' | 'tryForFree' | 'upgrade';
export type StepSelectorProps = Omit<SelectorProps, 'icon'> & {
  status: SubStepStatus;
  icon: keyof typeof icons;
};

const getStatusBadge = (status: SubStepStatus): ReactElement | null => {
  switch (status) {
    case 'confirmed':
      return <BadgeStatus level="validated" text="Completed" />;
    case 'prefilled':
      return <BadgeStatus level="waiting" text="To review" />;
    case 'tryForFree':
      return <Badge type="tryForFree" text="Try for free" />;
    case 'upgrade':
      return <Badge type="upgrade" text="Upgrade" />;
    default:
      return null;
  }
};

export function StepSelector({ title, status, icon, ...props }: StepSelectorProps): ReactElement {
  const computedTitle = (
    <span className={styles.title}>
      {title}
      {getStatusBadge(status)}
    </span>
  );

  const IconComponent = icons[icon];
  const computedIcon: ReactElement = <IconComponent />;

  return <Selector {...props} title={computedTitle} icon={computedIcon} />;
}
