import { type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from 'react-aria-components';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { useUpdateBulkActions } from 'qonto/react/hooks/use-update-bulk-actions';
import styles from './styles.strict-module.css';

interface FooterProps {
  onClose: () => void;
}

export function Footer({ onClose }: FooterProps): ReactNode {
  const {
    labels: { aggregatedUpdatedLabels },
    verificationStatus: { verificationStatusChanged },
    category: { categoryChanged },
    attachmentStatus: { attachmentStatusChanged },
    requestAttachment: { requestAttachment },
  } = bulkTransactionsManager.useBulkTransactions();
  const { mutate: mutateChangedFields, isPending } = useUpdateBulkActions();
  const labelsChanged = aggregatedUpdatedLabels.length > 0;

  const handleSubmit = (): void => {
    mutateChangedFields();
  };

  return (
    <div className={cx(styles.footer)} data-testid="footer">
      <Button onPress={onClose} className={cx(styles.button)} data-testid="cancel-button">
        Discard
      </Button>
      <Button
        onPress={handleSubmit}
        isDisabled={
          isPending ||
          (!labelsChanged &&
            !verificationStatusChanged &&
            !categoryChanged &&
            !attachmentStatusChanged &&
            !requestAttachment)
        }
        className={cx(styles.button, styles['button-primary'])}
        data-testid="submit-button"
      >
        {isPending ? 'Updating...' : 'Apply changes'}
      </Button>
    </div>
  );
}
