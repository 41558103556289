import cx from 'clsx';
import { Button, Checkbox, Tooltip } from '@repo/design-system-kit';
import { useEffect, useState, type Key, type ReactElement } from 'react';
import { ListBoxItem } from 'react-aria-components';
import { Info } from 'qonto/react/assets/icons/info';
import { Dropdown } from 'qonto/react/components/table-v2/dropdown';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { bulkAttachmentManager } from 'qonto/react/hooks/use-bulk-attachment-status';
import { bulkRequestAttachmentManager } from 'qonto/react/hooks/use-bulk-request-attachment';
import { ATTACHMENT_STATUS, type AttachmentStatusOption } from 'qonto/react/models/transaction';
import styles from './styles.strict-module.css';

const StatusOptions = [
  {
    id: ATTACHMENT_STATUS.LOST,
    title: 'Lost',
  },
  {
    id: ATTACHMENT_STATUS.REQUIRED,
    title: 'Required',
  },
  {
    id: ATTACHMENT_STATUS.NOT_REQUIRED,
    title: 'Not required',
  },
];

function RequestAttachmentCheckbox(): ReactElement {
  const {
    requestAttachment: { setRequestAttachment: bulkRequestAttachmentUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();
  const [requestAttachment, setRequestAttachment] = useState(false);
  const { disabledRequestAttachment } = bulkRequestAttachmentManager.useBulkRequestAttachment();
  const tooltipLabel =
    'Requests will be sent by e-mail to the members who initiated transactions without attachments. Several requests for the same member will send only one email.';
  const disabledTooltipLabel =
    'You cannot request attachments for internal transfers, your own transactions or on Qonto fees and subscriptions.';

  const handleRequestAttachmentChange = (): void => {
    setRequestAttachment(!requestAttachment);
    bulkRequestAttachmentUpdaterFn(!requestAttachment);
  };

  return (
    <div className={styles['attachment-request-wrapper']} data-testid="request-attachment">
      <Checkbox
        isSelected={requestAttachment}
        onChange={handleRequestAttachmentChange}
        isDisabled={disabledRequestAttachment}
      >
        Request attachments
      </Checkbox>
      <Tooltip
        closeDelay={0}
        delay={0}
        label={disabledRequestAttachment ? disabledTooltipLabel : tooltipLabel}
        placement="top"
        className={styles.tooltip}
      >
        <Button className={styles['tooltip-trigger']}>
          <Info className={styles.icon} />
        </Button>
      </Tooltip>
    </div>
  );
}

function AttachmentStatusSelect(): ReactElement {
  const { attachmentStatus: initialStatus, allHaveAttachments } =
    bulkAttachmentManager.useBulkAttachmentStatus();
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  const {
    attachmentStatus: { setAttachmentStatus: bulkAttachmentStatusUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();

  useEffect(() => {
    setSelectedStatus(initialStatus);
  }, [initialStatus]);

  const handleSelectionChange = (key: Key): void => {
    setSelectedStatus(key as AttachmentStatusOption);
    bulkAttachmentStatusUpdaterFn(key as AttachmentStatusOption, initialStatus);
  };

  return (
    <Dropdown
      label="Attachment"
      items={StatusOptions}
      onSelectionChange={handleSelectionChange}
      placeholder="Pick an option"
      selectedKey={selectedStatus}
      data-testid="attachment-select"
      isDisabled={allHaveAttachments}
    >
      {item => (
        <ListBoxItem
          key={item.id}
          textValue={item.title}
          className={cx(styles['list-item'], 'body-2')}
        >
          {item.title}
        </ListBoxItem>
      )}
    </Dropdown>
  );
}

export function AttachmentSection(): ReactElement {
  const { allHaveAttachments } = bulkAttachmentManager.useBulkAttachmentStatus();

  return (
    <section className={cx(styles.section)} data-testid="attachment-section">
      <AttachmentStatusSelect />
      {allHaveAttachments ? null : <RequestAttachmentCheckbox />}
    </section>
  );
}
