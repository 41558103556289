import { type ReactNode } from 'react';
import cx from 'clsx';
import { useEmberService, useIntl, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import type { TransactionModel } from 'qonto/react/models/transaction';
import styles from './footer.strict-module.css';

interface TransactionsSidebarFooterProps {
  highlightedItem: TransactionModel;
  handleShowHelpSection: () => void;
}

export function TransactionsSidebarFooter({
  highlightedItem,
  handleShowHelpSection,
}: TransactionsSidebarFooterProps): ReactNode {
  const { t } = useIntl();
  const flags = useFlags();
  const segment = useEmberService('segment');

  const onHelpButtonClick = (): void => {
    const event = flags.featurePrismicZendeskMigration
      ? 'transaction-helper_button_clicked'
      : 'navigation_transactionhelper_clicked';
    segment.track(event, { transaction_type: highlightedItem.operationType });
    handleShowHelpSection();
  };

  return (
    <>
      {!highlightedItem.isInstantTransfer && (
        <div data-test-sidebar-footer>
          <div className={cx(styles.footer)}>
            <button
              type="button"
              className={cx('btn btn--tertiary')}
              onClick={onHelpButtonClick}
              data-test-open-transaction-help-btn
            >
              {t('transactions.sidebar.help')}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
