import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface ProviderLogoProps {
  alt?: string;
  className?: string;
  size?: string;
  src: string;
}

export function ProviderLogo({
  src,
  alt,
  className,
  size = 'large',
  ...rest
}: ProviderLogoProps): ReactNode {
  return (
    <div className={cx(styles.wrapper, styles[size], className)} {...rest}>
      <img className={cx(styles.logo)} src={src} alt={alt} data-test-provider-logo-image />
      <span className={cx(styles.overlay)} />
    </div>
  );
}
