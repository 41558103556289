/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export enum ActivityTag {
  Atm = 'ATM',
  Fallback = 'FALLBACK',
  Fees = 'FEES',
  Finance = 'FINANCE',
  FoodAndGrocery = 'FOOD_AND_GROCERY',
  GasStation = 'GAS_STATION',
  HardwareAndEquipment = 'HARDWARE_AND_EQUIPMENT',
  HotelAndLodging = 'HOTEL_AND_LODGING',
  Insurance = 'INSURANCE',
  ItAndElectronics = 'IT_AND_ELECTRONICS',
  LegalAndAccounting = 'LEGAL_AND_ACCOUNTING',
  Logistics = 'LOGISTICS',
  Manufacturing = 'MANUFACTURING',
  Marketing = 'MARKETING',
  OfficeRental = 'OFFICE_RENTAL',
  OfficeSupply = 'OFFICE_SUPPLY',
  OnlineService = 'ONLINE_SERVICE',
  OtherExpense = 'OTHER_EXPENSE',
  OtherIncome = 'OTHER_INCOME',
  OtherService = 'OTHER_SERVICE',
  Pending = 'PENDING',
  Refund = 'REFUND',
  RestaurantAndBar = 'RESTAURANT_AND_BAR',
  Salary = 'SALARY',
  Sales = 'SALES',
  Subscription = 'SUBSCRIPTION',
  Tax = 'TAX',
  Transport = 'TRANSPORT',
  TreasuryAndInterco = 'TREASURY_AND_INTERCO',
  Utility = 'UTILITY',
  Voucher = 'VOUCHER',
}

export type Amount = {
  __typename?: 'Amount';
  currency: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AttachedFile = {
  __typename?: 'AttachedFile';
  contentType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  file?: Maybe<AttachedFile>;
  id: Scalars['ID']['output'];
  probativeAttachment?: Maybe<ProbativeAttachment>;
  slug: Scalars['String']['output'];
  thumbnail?: Maybe<Thumbnail>;
};

export enum AuthorizationType {
  Full = 'FULL',
  Remaining = 'REMAINING',
}

export type Avatar = {
  __typename?: 'Avatar';
  thumbUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Beneficiary = {
  __typename?: 'Beneficiary';
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Card = {
  __typename?: 'Card';
  holder?: Maybe<Membership>;
  holderId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
};

export type Counterparty = {
  __typename?: 'Counterparty';
  accountNumber: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export enum DeclinedReason {
  AccountInactive = 'ACCOUNT_INACTIVE',
  CannotVerifyPin = 'CANNOT_VERIFY_PIN',
  CardholderToContactIssuer = 'CARDHOLDER_TO_CONTACT_ISSUER',
  CustomerCancellation = 'CUSTOMER_CANCELLATION',
  DirectDebitPostponed = 'DIRECT_DEBIT_POSTPONED',
  DoNotHonor = 'DO_NOT_HONOR',
  ExceedsWithdrawalAmountLimit = 'EXCEEDS_WITHDRAWAL_AMOUNT_LIMIT',
  ExceedsWithdrawalFrequencyLimit = 'EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT',
  ExpiredCard = 'EXPIRED_CARD',
  IncorrectPin = 'INCORRECT_PIN',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InvalidAmount = 'INVALID_AMOUNT',
  InvalidCardNumber = 'INVALID_CARD_NUMBER',
  InvalidMerchant = 'INVALID_MERCHANT',
  IssuerOrSwitchIsInoperative = 'ISSUER_OR_SWITCH_IS_INOPERATIVE',
  LostCard = 'LOST_CARD',
  MerchantNameAndCountryUnknown = 'MERCHANT_NAME_AND_COUNTRY_UNKNOWN',
  NotPermittedToCardholder = 'NOT_PERMITTED_TO_CARDHOLDER',
  NotPermittedToTerminal = 'NOT_PERMITTED_TO_TERMINAL',
  OpsCanceled = 'OPS_CANCELED',
  PinTriesExceeded = 'PIN_TRIES_EXCEEDED',
  ResponseReceivedTooLate = 'RESPONSE_RECEIVED_TOO_LATE',
  RestrictedCard = 'RESTRICTED_CARD',
  SecurityViolation = 'SECURITY_VIOLATION',
  StolenCard = 'STOLEN_CARD',
  SystemMalfunction = 'SYSTEM_MALFUNCTION',
  Timeout = 'TIMEOUT',
  UnableToRouteTransaction = 'UNABLE_TO_ROUTE_TRANSACTION',
  UserCanceled = 'USER_CANCELED',
}

export type EnrichmentData = {
  __typename?: 'EnrichmentData';
  logo: Logo;
};

export type FilterGroupOne = {
  conditional?: InputMaybe<Scalars['String']['input']>;
  expressions?: InputMaybe<Array<FilterGroupOrExpressionOne>>;
};

export type FilterGroupOrExpressionOne = {
  conditional?: InputMaybe<Scalars['String']['input']>;
  expressions?: InputMaybe<Array<FilterGroupOrExpressionTwo>>;
  operator?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FilterGroupOrExpressionTwo = {
  conditional?: InputMaybe<Scalars['String']['input']>;
  expressions?: InputMaybe<Array<FilterGroupThree>>;
  operator?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FilterGroupThree = {
  operator?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Income = {
  __typename?: 'Income';
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['ID']['output'];
  labelList: LabelList;
  listId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LabelList = {
  __typename?: 'LabelList';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
};

export type Logo = {
  __typename?: 'Logo';
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type Membership = {
  __typename?: 'Membership';
  avatar: Avatar;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  role: Scalars['String']['output'];
  team: Team;
  teamId: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  author?: Maybe<Participant>;
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum OperationMethod {
  Biller = 'BILLER',
  Card = 'CARD',
  Cheque = 'CHEQUE',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  Other = 'OTHER',
  PagopaPayment = 'PAGOPA_PAYMENT',
  PayLater = 'PAY_LATER',
  Tax = 'TAX',
  Transfer = 'TRANSFER',
}

export enum OperationType {
  Biller = 'BILLER',
  Card = 'CARD',
  Cheque = 'CHEQUE',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitCollection = 'DIRECT_DEBIT_COLLECTION',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  F24 = 'F24',
  FinancingInstallment = 'FINANCING_INSTALLMENT',
  Income = 'INCOME',
  NrcPayment = 'NRC_PAYMENT',
  Other = 'OTHER',
  PagopaPayment = 'PAGOPA_PAYMENT',
  PayLater = 'PAY_LATER',
  Recall = 'RECALL',
  SwiftIncome = 'SWIFT_INCOME',
  Transfer = 'TRANSFER',
}

export type Pagination = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type Participant = {
  __typename?: 'Participant';
  id: Scalars['ID']['output'];
  lastReadAt?: Maybe<Scalars['String']['output']>;
  membership: Membership;
  membershipId: Scalars['ID']['output'];
};

export type PayableInvoice = {
  __typename?: 'PayableInvoice';
  attachmentId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
};

export type ProbativeAttachment = {
  __typename?: 'ProbativeAttachment';
  downloadUrl: Scalars['String']['output'];
  fileContentType: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  searchThreads: Array<Thread>;
  searchTransactions: SearchTransactionsResult;
};

export type QuerySearchThreadsArgs = {
  input?: InputMaybe<ThreadsSearch>;
};

export type QuerySearchTransactionsArgs = {
  input?: InputMaybe<TransactionsSearch>;
};

export type ReceivableInvoice = {
  __typename?: 'ReceivableInvoice';
  attachmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
};

export type SearchTransactionsMeta = {
  __typename?: 'SearchTransactionsMeta';
  currentPage: Scalars['Int']['output'];
  nextPage?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SearchTransactionsResult = {
  __typename?: 'SearchTransactionsResult';
  meta: SearchTransactionsMeta;
  transactions: Array<Transaction>;
};

export enum Side {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export type Sort = {
  direction?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export enum Status {
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Reversed = 'REVERSED',
}

export type StatusHistory = {
  __typename?: 'StatusHistory';
  createdAt: Scalars['String']['output'];
  status: StatusHistoryStatus;
};

export enum StatusHistoryStatus {
  Authorized = 'AUTHORIZED',
  Settled = 'SETTLED',
}

export enum SubjectType {
  BillingTransfer = 'BILLING_TRANSFER',
  Card = 'CARD',
  Check = 'CHECK',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitCollection = 'DIRECT_DEBIT_COLLECTION',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  F24Payment = 'F24_PAYMENT',
  FinancingIncome = 'FINANCING_INCOME',
  FinancingInstallment = 'FINANCING_INSTALLMENT',
  Income = 'INCOME',
  NrcPayment = 'NRC_PAYMENT',
  PagopaPayment = 'PAGOPA_PAYMENT',
  SwiftIncome = 'SWIFT_INCOME',
  Transfer = 'TRANSFER',
  WalletToWallet = 'WALLET_TO_WALLET',
}

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Thread = {
  __typename?: 'Thread';
  attachedToId: Scalars['ID']['output'];
  attachedToType: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  messages: Array<Message>;
  organizationId: Scalars['ID']['output'];
  participants: Array<Participant>;
};

export type ThreadsSearch = {
  attachedToIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  fileUrl: Scalars['String']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  activityTag?: Maybe<ActivityTag>;
  amount: Amount;
  attachmentIds: Array<Scalars['String']['output']>;
  attachmentLost: Scalars['Boolean']['output'];
  attachmentRequested: Scalars['Boolean']['output'];
  attachmentRequestedAt: Scalars['String']['output'];
  attachmentRequestedById?: Maybe<Scalars['String']['output']>;
  attachmentRequired: Scalars['Boolean']['output'];
  attachmentSuggestionIds: Array<Scalars['String']['output']>;
  attachments: Array<Attachment>;
  authorizationType?: Maybe<AuthorizationType>;
  automaticallyAddedAttachmentIds: Array<Scalars['String']['output']>;
  bankAccount?: Maybe<BankAccount>;
  bankAccountId: Scalars['String']['output'];
  card?: Maybe<Card>;
  cardTransactionStatus?: Maybe<Scalars['String']['output']>;
  counterparty?: Maybe<Counterparty>;
  counterpartyName: Scalars['String']['output'];
  declinedReason?: Maybe<DeclinedReason>;
  description?: Maybe<Scalars['String']['output']>;
  displayAt: Scalars['String']['output'];
  emittedAt: Scalars['String']['output'];
  enrichmentData: EnrichmentData;
  fx: Scalars['Boolean']['output'];
  fxPaymentPurpose?: Maybe<Scalars['String']['output']>;
  fxPaymentPurposeUnstructured?: Maybe<Scalars['String']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  hasAttachment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  income?: Maybe<Income>;
  initiator?: Maybe<Membership>;
  initiatorId?: Maybe<Scalars['String']['output']>;
  labelIds: Array<Scalars['String']['output']>;
  labels: Array<Label>;
  localAmount?: Maybe<Amount>;
  note?: Maybe<Scalars['String']['output']>;
  operationMethod: OperationMethod;
  operationType: OperationType;
  organizationId: Scalars['String']['output'];
  payableInvoices: Array<PayableInvoice>;
  qualifiedForAccounting: Scalars['Boolean']['output'];
  rawCounterpartyName?: Maybe<Scalars['String']['output']>;
  receivableInvoices: Array<ReceivableInvoice>;
  settledAt?: Maybe<Scalars['String']['output']>;
  settledBalance: Amount;
  side: Side;
  slug: Scalars['String']['output'];
  status: Status;
  statusHistory: Array<StatusHistory>;
  subjectId?: Maybe<Scalars['String']['output']>;
  subjectType?: Maybe<SubjectType>;
  thread?: Maybe<Thread>;
  transfer?: Maybe<Transfer>;
  vat: Vat;
};

export type TransactionsSearch = {
  bankAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filterGroup?: InputMaybe<FilterGroupOne>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
};

export type Transfer = {
  __typename?: 'Transfer';
  beneficiary?: Maybe<Beneficiary>;
  fx: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instant: Scalars['Boolean']['output'];
  internal: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
};

export type Vat = {
  __typename?: 'Vat';
  items: Array<VatItem>;
  status?: Maybe<VatStatus>;
  totalAmount: Amount;
};

export type VatItem = {
  __typename?: 'VatItem';
  amount: Amount;
  amountExcludingVat: Amount;
  id: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  transactionId: Scalars['String']['output'];
};

export enum VatStatus {
  VatAuto = 'VAT_AUTO',
  VatConfirmed = 'VAT_CONFIRMED',
  VatDetecting = 'VAT_DETECTING',
  VatDetectionError = 'VAT_DETECTION_ERROR',
  VatInheritedFromBeneficiary = 'VAT_INHERITED_FROM_BENEFICIARY',
  VatManual = 'VAT_MANUAL',
  VatNotFound = 'VAT_NOT_FOUND',
  VatOverridden = 'VAT_OVERRIDDEN',
  VatSuggested = 'VAT_SUGGESTED',
}

export type SearchTransactionsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
  sort: Sort;
  filterGroup?: InputMaybe<FilterGroupOne>;
  bankAccountIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type SearchTransactionsQuery = {
  __typename?: 'Query';
  searchTransactions: {
    __typename?: 'SearchTransactionsResult';
    meta: {
      __typename?: 'SearchTransactionsMeta';
      totalCount: number;
      totalPages: number;
      currentPage: number;
      perPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
    };
    transactions: Array<{
      __typename?: 'Transaction';
      id: string;
      activityTag?: ActivityTag | null;
      counterpartyName: string;
      emittedAt: string;
      rawCounterpartyName?: string | null;
      operationMethod: OperationMethod;
      description?: string | null;
      note?: string | null;
      settledAt?: string | null;
      side: Side;
      slug: string;
      status: Status;
      subjectType?: SubjectType | null;
      qualifiedForAccounting: boolean;
      attachmentLost: boolean;
      attachmentRequired: boolean;
      attachmentIds: Array<string>;
      attachmentRequested: boolean;
      amount: { __typename?: 'Amount'; value: string; currency: string };
      initiator?: {
        __typename?: 'Membership';
        id: string;
        fullName: string;
        firstName: string;
        lastName: string;
        email: string;
        role: string;
        avatar: { __typename?: 'Avatar'; url: string; thumbUrl: string };
        team: { __typename?: 'Team'; id: string; name: string };
      } | null;
      enrichmentData: {
        __typename?: 'EnrichmentData';
        logo: { __typename?: 'Logo'; small: string; medium: string };
      };
      localAmount?: { __typename?: 'Amount'; value: string; currency: string } | null;
      settledBalance: { __typename?: 'Amount'; value: string; currency: string };
      statusHistory: Array<{
        __typename?: 'StatusHistory';
        status: StatusHistoryStatus;
        createdAt: string;
      }>;
      attachments: Array<{
        __typename?: 'Attachment';
        slug: string;
        id: string;
        downloadUrl: string;
        createdAt: string;
        thumbnail?: { __typename?: 'Thumbnail'; fileUrl: string } | null;
        file?: {
          __typename?: 'AttachedFile';
          name: string;
          contentType: string;
          size: string;
          url: string;
        } | null;
        probativeAttachment?: {
          __typename?: 'ProbativeAttachment';
          status: string;
          fileContentType: string;
          fileName: string;
          fileSize: string;
          fileUrl: string;
          downloadUrl: string;
        } | null;
      }>;
      bankAccount?: {
        __typename?: 'BankAccount';
        id: string;
        bic: string;
        iban: string;
        name: string;
      } | null;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        name: string;
        listId: string;
        labelList: {
          __typename?: 'LabelList';
          id: string;
          name: string;
          color: string;
          rank: number;
        };
      }>;
      thread?: {
        __typename?: 'Thread';
        id: string;
        attachedToId: string;
        attachedToType: string;
        createdAt: string;
        messages: Array<{
          __typename?: 'Message';
          id: string;
          createdAt: string;
          content: string;
          author?: {
            __typename?: 'Participant';
            id: string;
            lastReadAt?: string | null;
            membership: {
              __typename?: 'Membership';
              fullName: string;
              email: string;
              role: string;
              avatar: { __typename?: 'Avatar'; url: string; thumbUrl: string };
              team: { __typename?: 'Team'; id: string; name: string };
            };
          } | null;
        }>;
        participants: Array<{
          __typename?: 'Participant';
          id: string;
          lastReadAt?: string | null;
          membership: {
            __typename?: 'Membership';
            fullName: string;
            email: string;
            role: string;
            avatar: { __typename?: 'Avatar'; url: string; thumbUrl: string };
            team: { __typename?: 'Team'; id: string; name: string };
          };
        }>;
      } | null;
      payableInvoices: Array<{
        __typename?: 'PayableInvoice';
        invoiceNumber?: string | null;
        fileName: string;
        attachmentId: string;
      }>;
      receivableInvoices: Array<{
        __typename?: 'ReceivableInvoice';
        invoiceNumber: string;
        attachmentId: string;
      }>;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        fx: boolean;
        slug: string;
        instant: boolean;
        internal: boolean;
        beneficiary?: {
          __typename?: 'Beneficiary';
          bic: string;
          iban: string;
          name: string;
          id: string;
        } | null;
      } | null;
      income?: {
        __typename?: 'Income';
        iban: string;
        name: string;
        bic: string;
        slug: string;
      } | null;
    }>;
  };
};

export const SearchTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Pagination' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sort' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterGroup' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterGroupOne' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterGroup' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterGroup' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bankAccountIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountIds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prevPage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'initiator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'thumbUrl' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'activityTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'counterpartyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emittedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrichmentData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'small' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawCounterpartyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'operationMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'settledAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settledBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'side' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qualifiedForAccounting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentLost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequested' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'probativeAttachment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileContentType' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bankAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thread' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attachedToId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attachedToType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'messages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastReadAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'membership' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'url' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'thumbUrl' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'team' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'participants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastReadAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'membership' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fullName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatar' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'thumbUrl' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'team' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payableInvoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attachmentId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receivableInvoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attachmentId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transfer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fx' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'internal' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'beneficiary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'income' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchTransactionsQuery, SearchTransactionsQueryVariables>;
