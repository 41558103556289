import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { useCopyToClipboard } from 'usehooks-ts';
import { Button, Tooltip } from '@repo/design-system-kit';
import { Copy } from 'qonto/react/assets/icons/copy';
import styles from './styles.strict-module.css';

interface CopyButtonProps {
  text: string;
  className?: string;
}

export function CopyButton({ text, className }: CopyButtonProps): ReactNode {
  const [_copiedText, copy] = useCopyToClipboard();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const handleCopy = async (): Promise<void> => {
    try {
      await copy(text);
      toastFlashMessages.toastInfo('Copied to clipboard');
    } catch (error) {
      //
    }
  };

  const onClickHandler = (): void => {
    void handleCopy();
  };

  return (
    <Tooltip
      closeDelay={0}
      delay={300}
      label="Copy to clipboard"
      placement="top"
      tooltipTestId="tooltip"
    >
      <Button
        className={cx(styles.button, className)}
        variant="tertiary"
        iconOnly
        type="button"
        onPress={onClickHandler}
        data-testid="copy-button"
      >
        <Copy />
      </Button>
    </Tooltip>
  );
}
